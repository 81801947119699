<template>
    <div class="page">
        <div class="cockpit_top">
            <div class="cockpit_top_item">
                <div class="cockpit_top_item_bar bg1">
                    <el-image
                        class="cockpit_top_item_bar_icon"
                        :src="require('@/assets/cockpit/icon1.png')"
                        mode="contain"
                    ></el-image>
                    <div class="cockpit_top_item_bar_text">
                        入职离职人数统计
                    </div>
                </div>
                <div class="cockpit_top_item_content">
                    <div class="cockpit_top_item_content_cards">
                        <div class="cockpit_top_item_content_cards_item">
                            <div>
                                {{
                                    statistics.staff_statistics &&
                                    statistics.staff_statistics.column_1
                                }}
                            </div>
                            <div>本月新入职</div>
                        </div>
                        <div class="cockpit_top_item_content_cards_item">
                            <div>
                                {{
                                    statistics.staff_statistics &&
                                    statistics.staff_statistics.column_2
                                }}
                            </div>
                            <div>本月已离职</div>
                        </div>
                        <div class="cockpit_top_item_content_cards_item">
                            <div>
                                {{
                                    statistics.staff_statistics &&
                                    statistics.staff_statistics.column_3
                                }}
                            </div>
                            <div>上月新入职</div>
                        </div>
                        <div class="cockpit_top_item_content_cards_item">
                            <div>
                                {{
                                    statistics.staff_statistics &&
                                    statistics.staff_statistics.column_4
                                }}
                            </div>
                            <div>上月已离职</div>
                        </div>
                    </div>
                    <div class="chart" id="chart1"></div>
                </div>
            </div>
            <div class="cockpit_top_item">
                <div class="cockpit_top_item_bar bg2">
                    <el-image
                        class="cockpit_top_item_bar_icon"
                        :src="require('@/assets/cockpit/icon2.png')"
                        mode="contain"
                    ></el-image>
                    <div class="cockpit_top_item_bar_text">员工考勤统计</div>
                </div>
                <div class="cockpit_top_item_content">
                    <div class="cockpit_top_item_content_cards">
                        <div
                            class="cockpit_top_item_content_cards_item"
                            @click="punchDetail('late')"
                        >
                            <div>
                                {{
                                    statistics.punch_statistics &&
                                    statistics.punch_statistics.column_1
                                }}
                            </div>
                            <div>本月迟到</div>
                        </div>
                        <div
                            class="cockpit_top_item_content_cards_item"
                            @click="punchDetail('leave_early')"
                        >
                            <div>
                                {{
                                    statistics.punch_statistics &&
                                    statistics.punch_statistics.column_2
                                }}
                            </div>
                            <div>本月早退</div>
                        </div>
                        <div
                            class="cockpit_top_item_content_cards_item"
                            @click="punchDetail('absenteeism')"
                        >
                            <div>
                                {{
                                    statistics.punch_statistics &&
                                    statistics.punch_statistics.column_3
                                }}
                            </div>
                            <div>本月旷工</div>
                        </div>
                        <div
                            class="cockpit_top_item_content_cards_item"
                            @click="punchDetail('leave')"
                        >
                            <div>
                                {{
                                    statistics.punch_statistics &&
                                    statistics.punch_statistics.column_4
                                }}
                            </div>
                            <div>本月请假</div>
                        </div>
                    </div>
                    <!-- <el-table
                        :data="attendanceData"
                        :header-row-style="{ backgroundColor: '#F5F8FA' }"
                        :header-cell-style="{
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                        }"
                        :cell-style="{ textAlign: 'center' }"
                    >
                        <el-table-column
                            property="content"
                            label="部门"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="人数"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="迟到"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="早退"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="旷工"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="请假"
                        ></el-table-column>
                    </el-table> -->
                    <div class="chart" id="chart2"></div>
                </div>
            </div>
        </div>

        <div class="cockpit_bottom">
            <div class="cockpit_bottom_item">
                <div class="cockpit_bottom_item_bar bg3">
                    <el-image
                        class="cockpit_bottom_item_bar_icon"
                        :src="require('@/assets/cockpit/icon3.png')"
                        mode="contain"
                    ></el-image>
                    <div class="cockpit_bottom_item_bar_text">合同签署统计</div>
                </div>
                <div class="cockpit_bottom_item_content">
                    <div class="cockpit_bottom_item_content_cards">
                        <div
                            class="cockpit_bottom_item_content_cards_item"
                            @click="contractDetail(1)"
                        >
                            <div>
                                {{
                                    statistics.contract_statistics &&
                                    statistics.contract_statistics.column_1
                                }}
                            </div>
                            <div>上月新签订人数</div>
                        </div>
                        <div
                            class="cockpit_bottom_item_content_cards_item"
                            @click="contractDetail(2)"
                        >
                            <div>
                                {{
                                    statistics.contract_statistics &&
                                    statistics.contract_statistics.column_2
                                }}
                            </div>
                            <div>本月新签订人数</div>
                        </div>
                    </div>
                    <!-- <el-table
                        :data="attendanceData"
                        :header-row-style="{ backgroundColor: '#F5F8FA' }"
                        :header-cell-style="{
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                        }"
                        :cell-style="{ textAlign: 'center' }"
                    >
                        <el-table-column
                            property="content"
                            label="部门"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="人数"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="应发工资"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="实发工资"
                        ></el-table-column>
                    </el-table> -->
                    <div
                        class="chart"
                        id="chart3"
                        style="margin-top: 20px;"
                    ></div>
                </div>
            </div>
            <div class="cockpit_bottom_item">
                <div class="cockpit_bottom_item_bar bg4">
                    <el-image
                        class="cockpit_bottom_item_bar_icon"
                        :src="require('@/assets/cockpit/icon4.png')"
                        mode="contain"
                    ></el-image>
                    <div class="cockpit_bottom_item_bar_text">人工成本统计</div>
                </div>
                <div class="cockpit_bottom_item_content">
                    <div class="cockpit_bottom_item_content_cards">
                        <div
                            class="cockpit_bottom_item_content_cards_item"
                            @click="salaryDetail(1)"
                        >
                            <div>
                                {{
                                    statistics.salary_statistics &&
                                    statistics.salary_statistics.column_1
                                }}
                            </div>
                            <div>上月实发工资</div>
                        </div>
                        <div
                            class="cockpit_bottom_item_content_cards_item"
                            @click="salaryDetail(2)"
                        >
                            <div>
                                {{
                                    statistics.salary_statistics &&
                                    statistics.salary_statistics.column_2
                                }}
                            </div>
                            <div>本月实发工资</div>
                        </div>
                    </div>
                    <!-- <el-table
                        :data="attendanceData"
                        :header-row-style="{ backgroundColor: '#F5F8FA' }"
                        :header-cell-style="{
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                        }"
                        :cell-style="{ textAlign: 'center' }"
                    >
                        <el-table-column
                            property="content"
                            label="部门"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="人数"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="应发工资"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="实发工资"
                        ></el-table-column>
                    </el-table> -->
                    <div
                        class="chart"
                        id="chart4"
                        style="margin-top: 20px;"
                    ></div>
                </div>
            </div>
            <div class="cockpit_bottom_item">
                <div class="cockpit_bottom_item_bar bg5">
                    <el-image
                        class="cockpit_bottom_item_bar_icon"
                        :src="require('@/assets/cockpit/icon5.png')"
                        mode="contain"
                    ></el-image>
                    <div class="cockpit_bottom_item_bar_text">险种详情</div>
                </div>
                <div class="cockpit_bottom_item_content">
                    <div class="cockpit_bottom_item_content_cards">
                        <div
                            class="cockpit_bottom_item_content_cards_item"
                            @click="insuranceDetail(1)"
                        >
                            <div>
                                {{
                                    statistics.insurance_statistics &&
                                    statistics.insurance_statistics.column_1
                                }}
                            </div>
                            <div>上月购买险种人数</div>
                        </div>
                        <div
                            class="cockpit_bottom_item_content_cards_item"
                            @click="insuranceDetail(2)"
                        >
                            <div>
                                {{
                                    statistics.insurance_statistics &&
                                    statistics.insurance_statistics.column_2
                                }}
                            </div>
                            <div>本月购买险种人数</div>
                        </div>
                    </div>
                    <!-- <el-table
                        :data="attendanceData"
                        :header-row-style="{ backgroundColor: '#F5F8FA' }"
                        :header-cell-style="{
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                        }"
                        :cell-style="{ textAlign: 'center' }"
                    >
                        <el-table-column
                            property="content"
                            label="部门"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="人数"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="应发工资"
                        ></el-table-column>
                        <el-table-column
                            property="content"
                            label="实发工资"
                        ></el-table-column>
                    </el-table> -->
                    <div
                        class="chart"
                        id="chart5"
                        style="margin-top: 20px;"
                    ></div>
                </div>
            </div>
        </div>

        <el-dialog title="合同签署统计" :visible.sync="contractVisible">
            <el-table :data="contractTableData">
                <el-table-column
                    property="department_data.department_name"
                    label="部门"
                ></el-table-column>
                <el-table-column
                    property="real_name"
                    label="名字"
                ></el-table-column>
                <el-table-column
                    property="idcard"
                    label="身份证"
                ></el-table-column>
                <el-table-column
                    property="mobile"
                    label="手机"
                ></el-table-column>
                <el-table-column
                    property="needSignContractList"
                    label="未签合同"
                >
                    <template slot-scope="scope">
                        {{
                            scope.row.needSignContractList
                                .map((item) => item.title)
                                .join("，")
                        }}
                    </template>
                </el-table-column>
                <!-- <el-table-column fixed="right" label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button type="text" size="small">
                            编辑
                        </el-button>
                    </template>
                </el-table-column> -->
            </el-table>
        </el-dialog>

        <el-dialog title="险种详情" :visible.sync="insuranceVisible">
            <el-table :data="insuranceTableData">
                <el-table-column
                    property="staff.department_data.department_name"
                    label="部门"
                ></el-table-column>
                <el-table-column
                    property="staff.real_name"
                    label="名字"
                ></el-table-column>
                <el-table-column
                    property="staff.idcard"
                    label="身份证"
                ></el-table-column>
                <el-table-column
                    property="staff.mobile"
                    label="手机"
                ></el-table-column>
                <el-table-column property="content" label="内容">
                </el-table-column>
                <!-- <el-table-column fixed="right" label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button type="text" size="small">
                            编辑
                        </el-button>
                    </template>
                </el-table-column> -->
            </el-table>
        </el-dialog>

        <el-dialog title="人工成本统计" :visible.sync="salaryVisible">
            <el-table :data="salaryTableData">
                <el-table-column
                    property="staff.department_data.department_name"
                    label="部门"
                ></el-table-column>
                <el-table-column
                    property="staff.real_name"
                    label="名字"
                ></el-table-column>
                <el-table-column
                    property="staff.idcard"
                    label="身份证"
                ></el-table-column>
                <el-table-column
                    property="staff.mobile"
                    label="手机"
                ></el-table-column>
                <el-table-column property="final_pay" label="实发工资">
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog title="员工考勤统计" :visible.sync="punchVisible">
            <el-table :data="punchTableData">
                <el-table-column
                    property="department_data.department_name"
                    label="部门"
                ></el-table-column>
                <el-table-column
                    property="real_name"
                    label="名字"
                ></el-table-column>
                <el-table-column
                    property="idcard"
                    label="身份证"
                ></el-table-column>
                <el-table-column
                    property="mobile"
                    label="手机"
                ></el-table-column>
            </el-table>
            <el-pagination
                layout="prev, pager, next"
                :total="page.total"
                :page-size="page.size"
                :current-page.sync="page.page"
                @current-change="loadData()"
            >
            </el-pagination>
        </el-dialog>
    </div>
</template>

<script>
import { Chart } from "@antv/g2";
import { mapActions } from "vuex";

export default {
    data() {
        return {
            statistics: {},
            attendanceData: [
                {
                    content: 1,
                },
            ],
            insuranceVisible: false,
            insuranceTableData: [],
            salaryVisible: false,
            salaryTableData: [],
            contractVisible: false,
            contractTableData: [],
            punchVisible: false,
            punchTableData: [],
            // 分页参数
            page: {
                size: 15,
                page: 1,
                total: 0,
            },
            loadData: () => {},
        };
    },
    created() {
        this.getdepartmentList()
            .then((res) => {
                console.log(res);
                this.statistics = res.data.data;
            })
            .catch((e) => {
                console.error(e);
            });
    },
    mounted() {
        this.loadChart1();
        this.loadChart2();
        this.loadChart3();
        this.loadChart4();
        this.loadChart5();
    },
    methods: {
        ...mapActions("cockpit", [
            "getdepartmentList",
            "getStaffDataChart",
            "getPunchDataChart",
            "getSalaryDataChart",
            "getContractDataChart",
            "getInsuranceDataChart",
            "getInsuranceDetail",
            "getSalaryDetail",
            "getContractDetail",
            "getPunchDetail",
        ]),

        async punchDetail(type) {
            this.page.page = 1;
            this.loadData = async () => {
                let loading = this.$loading();
                try {
                    let res = await this.getPunchDetail({ type, ...this.page });
                    console.log(res);
                    this.punchTableData = this.punchTableData.concat(res.data.data.list);
                } catch (e) {
                    console.error(e);
                    this.$message.error(e.msg);
                }
                this.$nextTick(() => {
                    loading.close();
                });
            };
            await this.loadData();
            this.punchVisible = true;
        },

        async insuranceDetail(type) {
            let loading = this.$loading();
            try {
                let res = await this.getInsuranceDetail({ type });
                console.log(res);
                this.insuranceTableData = res.data.data.list;
                this.insuranceVisible = true;
            } catch (e) {
                console.error(e);
                this.$message.error(e.msg);
            }
            this.$nextTick(() => {
                loading.close();
            });
        },

        async salaryDetail(type) {
            let loading = this.$loading();
            try {
                let res = await this.getSalaryDetail({ type });
                console.log(res);
                this.salaryTableData = res.data.data.list;
                this.salaryVisible = true;
            } catch (e) {
                console.error(e);
                this.$message.error(e.msg);
            }
            this.$nextTick(() => {
                loading.close();
            });
        },

        async contractDetail(type) {
            let loading = this.$loading();
            try {
                let res = await this.getContractDetail({ type });
                console.log(res);
                this.contractTableData = res.data.data.list;
                this.contractVisible = true;
            } catch (e) {
                console.error(e);
                this.$message.error(e.msg);
            }
            this.$nextTick(() => {
                loading.close();
            });
        },

        async loadChart1() {
            let res = await this.getStaffDataChart({});
            res = res.data.data.list;
            console.log("入离职", res);
            let data = [];
            for (
                let i = 0;
                i < Math.min(res.entry.length, res.quit.length);
                i++
            ) {
                res.entry[i];
                res.quit[i];
                data.push({
                    country: res.entry[i].month,
                    type: "入职人数",
                    value: res.entry[i].count,
                });
                data.push({
                    country: res.quit[i].month,
                    type: "离职人数",
                    value: res.quit[i].count,
                });
            }

            const chart = new Chart({
                container: "chart1",
                width: 700,
                height: 350,
                padding: [0, 0, 0, 50],
            });
            chart.data(data);

            chart.axis("value", false);
            chart.coordinate().transpose();
            chart.facet("mirror", {
                fields: ["type"],
                transpose: true,
                showTitle: false,
                eachView: (view, facet) => {
                    const facetIndex = facet.columnIndex;
                    if (facetIndex === 0) {
                        view.axis("country", {
                            position: "top",
                            label: {
                                style: {
                                    fill: "#aaaaaa",
                                    fontSize: 12,
                                },
                            },
                            tickLine: {
                                alignTick: false,
                                length: 0,
                            },
                            line: null,
                        });
                        view.scale("value", {
                            alias: "入职人数",
                        });
                    } else {
                        view.axis("country", false);
                        view.scale("value", {
                            alias: "离职人数",
                        });
                    }
                    const color = facetIndex === 0 ? "#1890ff" : "#2fc25b";
                    view.interval()
                        .position("country*value")
                        .color(color)
                        .label("value", () => {
                            let offset = facetIndex === 1 ? -4 : 4;
                            let shadowBlur = 2;
                            let textAlign = facetIndex === 1 ? "end" : "start";
                            let fill = "white";
                            return {
                                offset,
                                style: {
                                    fill,
                                    stroke: null,
                                    shadowBlur,
                                    shadowColor: "rgba(0, 0, 0, .45)",
                                    textAlign,
                                },
                            };
                        });
                },
            });
            chart.interaction("element-highlight");
            chart.render();
        },

        async loadChart2() {
            let res = await this.getPunchDataChart({
                month: this.$options.filters.formatTime(new Date(), "yyyy-MM"),
            });
            res = res.data.data.list;
            console.log("考勤", res);
            const count =
                res.late +
                res.leave_early +
                res.leave +
                res.absenteeism +
                res.normal;
            const data = [
                {
                    item: "迟到",
                    count: res.late,
                    percent: res.late / count,
                },
                {
                    item: "早退",
                    count: res.leave_early,
                    percent: res.leave_early / count,
                },
                {
                    item: "旷工",
                    count: res.absenteeism,
                    percent: res.absenteeism / count,
                },
                {
                    item: "请假",
                    count: res.leave,
                    percent: res.leave / count,
                },
                {
                    item: "正常",
                    count: res.normal,
                    percent: res.normal / count,
                },
            ];
            console.log(data);

            // const chart = new Chart({
            //     container: "chart2",
            //     width: 400,
            //     height: 250,
            // });
            const chart = new Chart({
                container: "chart2",
                width: 700,
                height: 350,
            });

            chart.coordinate("theta", {
                radius: 0.75,
            });

            chart.data(data);

            chart.scale("percent", {
                formatter: (val) => {
                    val = (val * 100).toFixed(0) + "%";
                    return val;
                },
            });

            chart.tooltip({
                showTitle: false,
                showMarkers: false,
            });

            chart
                .interval()
                .adjust("stack")
                .position("percent")
                .color("item")
                .label("percent", {
                    offset: "-30%",
                    style: {
                        textAlign: "center",
                        shadowBlur: 2,
                        shadowColor: "rgba(0, 0, 0, .45)",
                        fontSize: 20,
                    },
                })
                .tooltip("item*percent", (item, percent) => {
                    percent = (percent * 100).toFixed(0) + "%";
                    return {
                        name: item,
                        value: percent,
                    };
                });

            chart.interaction("element-active");
            chart.render();
        },

        async loadChart3() {
            let res = await this.getContractDataChart({
                month: this.$options.filters.formatTime(new Date(), "yyyy-MM"),
            });
            res = res.data.data.list;
            console.log("合同", res);

            const data = res.map((item) => {
                return {
                    name: item.name,
                    num: item.sign_num,
                };
            });

            const chart = new Chart({
                container: "chart3",
                width: 343,
                // height: 250,
                height: 350,
            });
            chart.data(data);
            chart.coordinate("polar");
            chart.legend("name", {
                position: "right",
            });
            chart.axis(false);
            chart.tooltip({
                showMarkers: false,
            });
            chart.interaction("element-highlight");
            chart.interval().position("name*num").color("name").style({
                lineWidth: 1,
                stroke: "#fff",
            });
            chart.render();
        },

        async loadChart4() {
            let res = await this.getSalaryDataChart({
                month: this.$options.filters.formatTime(new Date(), "yyyy-MM"),
            });
            res = res.data.data.list;
            console.log("人工", res);
            let data = [];
            for (const item of res) {
                data.push({
                    name: "应付工资",
                    month: item.month,
                    value: item.salary,
                });
                data.push({
                    name: "实发工资",
                    month: item.month,
                    value: item.final_salary,
                });
            }

            const chart = new Chart({
                container: "chart4",
                width: 500,
                // height: 250,
                height: 350,
            });

            chart.data(data);
            chart.scale("value", {
                nice: true,
                formatter(value) {
                    return value + " 元";
                },
            });
            chart.tooltip({
                shared: true,
                showMarkers: false,
            });

            chart
                .interval()
                .position("month*value")
                .color("name")
                .adjust("stack");

            chart.interaction("active-region");

            chart.render();
        },

        async loadChart5() {
            let res = await this.getInsuranceDataChart({
                year: this.$options.filters.formatTime(new Date(), "yyyy"),
            });
            res = res.data.data.list;
            console.log("险种", res);
            const data = [];
            res.forEach((item) => {
                item.insurance_list.forEach((insurance) => {
                    data.push({
                        month: item.month,
                        type: insurance.name,
                        value: insurance.buy_num,
                    });
                });
            });

            const chart = new Chart({
                container: "chart5",
                width: 500,
                // height: 250,
                height: 350,
            });

            chart.coordinate().transpose().scale(1, -1);

            chart.data(data);

            chart.scale({
                value: {
                    alias: "购买人数",
                },
            });

            chart.axis("month", {
                label: {
                    style: {
                        fill: "#aaaaaa",
                        fontSize: 12,
                    },
                },
                tickLine: null,
                title: null,
            });
            chart.axis("value", {
                label: {
                    style: {
                        fill: "#aaaaaa",
                        fontSize: 12,
                    },
                },
                title: {
                    style: {
                        fontSize: 12,
                        fontWeight: 300,
                    },
                    position: "end",
                },
            });

            chart.legend({
                position: "right-bottom",
            });

            chart.tooltip({
                shared: true,
                showMarkers: false,
            });

            chart
                .interval()
                .position("month*value")
                .color("type")
                .adjust([
                    {
                        type: "dodge",
                        marginRatio: 0.3,
                    },
                ]);

            chart.interaction("active-region");
            chart.interaction("legend-highlight");

            chart.render();
        },
    },
};
</script>

<style lang="scss" scoped>
.page {
    padding: 10px;
}

.cockpit_top,
.cockpit_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cockpit_top_item,
    .cockpit_bottom_item {
        flex: 1;
        width: max-content;
        margin: 20px 10px;

        .cockpit_top_item_bar,
        .cockpit_bottom_item_bar {
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            background-size: contain;
            padding: 0 20rpx;

            &.bg1 {
                background-image: url("~@/assets/cockpit/bar1.png");
            }

            &.bg2 {
                background-image: url("~@/assets/cockpit/bar2.png");
            }

            &.bg3 {
                background-image: url("~@/assets/cockpit/bar3.png");
            }

            &.bg4 {
                background-image: url("~@/assets/cockpit/bar4.png");
            }

            &.bg5 {
                background-image: url("~@/assets/cockpit/bar5.png");
            }

            .cockpit_top_item_bar_icon,
            .cockpit_bottom_item_bar_icon {
                margin-left: 20px;
            }

            .cockpit_top_item_bar_text,
            .cockpit_bottom_item_bar_text {
                font-size: 16px;
                color: #1c2438;
                margin-left: 10px;
            }
        }

        .cockpit_top_item_content,
        .cockpit_bottom_item_content {
            height: 500px;
            border: 1px solid #f0f4f8;
            padding: 0 20px;

            .cockpit_top_item_content_cards,
            .cockpit_bottom_item_content_cards {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                margin: 30px 0;

                .cockpit_top_item_content_cards_item,
                .cockpit_bottom_item_content_cards_item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    > div:nth-child(1) {
                        font-size: 18px;
                        color: #1c2438;
                        line-height: 30px;
                    }

                    > div:nth-child(2) {
                        font-size: 14px;
                        color: #80848f;
                        line-height: 30px;
                    }
                }
            }

            .chart {
                width: max-content;
                margin: 0 auto;
            }
        }
    }
}
</style>
